@import 'core/settings/settings';
@import 'core/tools/tools';

/**
 * Markdown layout
 */
.intro-form-page__markdown {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  max-width: $max-content-width;
  margin: 0 auto;

  & > * {
    width: 100%;
  }

  ul {
    margin-bottom: calc-size(map-get($spacing, '200'));
  }

  @include set-breakpoint('small') {
    ul {
      max-width: 540px;
    }
  }

  @include set-breakpoint('x-large') {
    h3, ul {
      max-width: 390px;
      margin: 0 calc-size(map-get($spacing, '150')) 0 calc-size(map-get($spacing, '150'));
    }

    h3 {
      order: 3;
    }

    ul {
      order: 4;
    }

    h2:last-child {
      order: 5;
    }
  }
}
